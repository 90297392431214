import React from 'react';
import {Typography} from "@mui/material";
import DisclaimerButton from "../station_pop_up/DisclaimerButton";

const Row = ({indexData, colors}) => {
    return <div style={{
        display: "flex", width: "100vw", zIndex: 1999
    }}>
        {indexData.length > 0 && indexData.map((item, index) => {
            return <div key={index} style={{
                display: "flex",
                alignItems: "center",
            }}>
                <div style={{
                    marginLeft: 12,
                    minWidth: 13,
                    height: 10,
                    borderRadius: 5,
                    background: colors[index]
                }}/>
                <Typography
                    noWrap
                    style={{color: "#626262", paddingLeft: 6}}
                    data-testid={"aqi-element"}
                    variant={"subtitle2"}>{item}</Typography>
            </div>
        })}
    </div>
};

const MobileLegend = ({openDetail, data}) => {

    const {colors, indexInfo} = data;

    return !openDetail && <div style={{
        display: "flex",
        flexDirection: "column",
        overflowX: "scroll",
        background: "#fff",
        width: "100vw",
        zIndex: 1999,
        position: "relative",
        boxShadow: "0 2px 2px rgba(0,0,0,0.2)"
    }}>
        <Row indexData={indexInfo.slice(0, 4)} colors={colors}/>
        <Row indexData={indexInfo.slice(4, indexInfo.legend)} colors={colors.slice(4, indexInfo.legend)}/>
        <DisclaimerButton style={{marginTop: 4}}/>
    </div>;
};

export default MobileLegend;
