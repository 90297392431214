import "./map_style.css"
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import mapBoxGL from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {isMobile, isTablet} from 'react-device-detect';
import {useWindowSize} from "../../hooks/useWindowsSize";
import StationDetail from "./detail/StationDetail";
import {useLocation} from "react-router-dom";
import StationPopUp from "./station_pop_up/StationPopUp";
import {organizationRequest} from "../../request/dataRequest";
import NotFound from "../NotFound";
import {Backdrop, CircularProgress, SwipeableDrawer, Typography} from "@mui/material";
import applyMarkerStyle from "../utils/applyMarkerStyle";
import MapLegend from "./legend/MapLegend";
import MapSelector, {mapOptions} from "./MapSelector";
import {useTranslation} from "react-i18next";


const queryString = require('query-string');

const MOBILE_SIZE = 800;
export const DESKTOP_DETAIL_HEIGHT = 420;

const SATELLITE_MAP_STYLE = "mapbox://styles/mapbox/satellite-streets-v10";
const STREET_MAP_STYLE = 'mapbox://styles/mapbox/light-v10';

const MapView = () => {

    const version = process.env.REACT_APP_VERSION;
    const {t} = useTranslation();
    const location = useLocation();
    const size = useWindowSize();
    const mapRef = useRef(null);
    const markers = useRef([]);
    const organizationStations = useRef([]);
    const [selectedMap, selectedSelectedMap] = useState(mapOptions.MAP)

    const setSelectedMapCallback = useCallback((_, newSelection) => {
        if (newSelection !== null) {
            selectedSelectedMap(newSelection)
        }
    }, []);

    const [{data, loading, openMenu, selectedStation, openPopUp, lastLocation, notFound, path}, updateState] = useState(
        {
            data: null, loading: true, openMenu: false, selectedStation: null, openPopUp: false,
            lastLocation: {}, notFound: false, path: ""
        })

    const showDetail = useCallback((id, position, newLastLocation = null) => {

        mapRef.current?.flyTo({
                center: [position.long, position.lat - 0.0015],
                zoom: 16,
                speed: 4,
                curve: 1
            }
        )


        updateState(state => ({
            ...state,
            lastLocation: newLastLocation === null ? {
                zoom: mapRef.current.getZoom(),
                center: mapRef.current.getCenter()
            } : {...newLastLocation},
            openPopUp: true,
            openMenu: false,
            selectedStation: organizationStations.current.find(station => station.id === id)
        }))

    }, [])

    const isMobileDevice = useMemo(() => {
        return isMobile && !isTablet ? true :
            isMobile && isTablet ? false :
                size.width <= MOBILE_SIZE
    }, [size.width])


    const loadMapInfo = (organizationPath, selectedStationId) => {
        organizationRequest(organizationPath, (error, data) => {
            if (!error) processData(data, organizationPath, selectedStationId);
            else updateState(state => ({...state, notFound: true}));
        });
    }

    const processData = useCallback((data, organizationPath, selectedStationId) => {
        updateState(state => ({
                ...state,
                data: data,
                pollutantIndex: data.index,
                loading: false,
                path: organizationPath
            }
        ))
        organizationStations.current = data.stations

        const stationsWithLocation = data.stations.filter(station => station.position !== undefined)
        markers.current?.forEach(value => {
            if (value !== undefined) {
                value.remove();
            }
        })

        markers.current = stationsWithLocation.map(value => {
            let el = document.createElement('div');
            el.id = value.id
            el.alias = value.alias
            el.position = value.position
            applyMarkerStyle(el, value, data.units.index);

            el.addEventListener('click', function () {
                showDetail(el.id, el.position, null);
            });
            return new mapBoxGL.Marker(el)
                .setLngLat({lng: value.position.long, lat: value.position.lat}).addTo(mapRef.current);
        });

        let selectedStation = selectedStationId !== null ? data.stations.find(station => station.id === selectedStationId) : undefined
        if (selectedStation !== undefined) {
            showDetail(selectedStationId, selectedStation.position, {
                zoom: isMobileDevice ? data.map.zoom - 2 : data.map.zoom,
                center: {lng: data.map.center.lon, lat: data.map.center.lat}
            },)
        } else {
            mapRef.current?.flyTo({
                center: [data.map.center.lon, data.map.center.lat],
                zoom: isMobileDevice ? data.map.zoom - 2 : data.map.zoom,
                speed: 2,
                curve: 1
            })
        }
    }, [isMobileDevice, showDetail]);


    useEffect(() => {
        const pathArray = location.pathname.split("/");
        const organizationPath = pathArray[1];
        const selectedStationId = queryString.parse(location.search).id || null;

        mapBoxGL.accessToken = 'pk.eyJ1Ijoiam1zYWJpbjEzMjciLCJhIjoiY2ttZWx0dHZhMndmbDJ4a25sazFiOWs5ZiJ9.bm1M1koxEnJI_jwx5OCbyQ';
        mapRef.current = new mapBoxGL.Map({
            container: 'map',
            style: selectedMap === mapOptions.MAP ? STREET_MAP_STYLE : SATELLITE_MAP_STYLE,
            boxZoom: true,
            zoom: 3,
            center: [2.1092370578854895, 41.354975300611756]
        });

        mapRef.current?.addControl(new mapBoxGL.NavigationControl(), !isMobile || (isMobile && isTablet) ? 'top-right' : 'bottom-right');

        mapRef.current?.dragRotate.disable();

        mapRef.current?.on('dragstart', () => {
            updateState(state => ({...state, openPopUp: false}))
        });
        loadMapInfo(organizationPath, selectedStationId);

        document.getElementById('splash-screen').style.display = 'none';

        return () => {
            mapRef.current = null
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        mapRef.current?.setStyle(selectedMap === mapOptions.MAP ? STREET_MAP_STYLE : SATELLITE_MAP_STYLE)
    }, [selectedMap]);

    const navigateBack = useCallback(() => {
        lastLocation.center && mapRef.current?.flyTo({
            center: [lastLocation.center.lng, lastLocation.center.lat],
            zoom: lastLocation.zoom,
            speed: 4,
            curve: 1
        })
    }, [lastLocation.center, lastLocation.zoom])

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        navigateBack()
        updateState(state => ({...state, openMenu: open}));
    };

    const onClosePopUp = useCallback(() => {
        navigateBack()
        updateState(state => ({...state, openPopUp: false}))
    }, [navigateBack])

    const onHistorySelected = useCallback(() => {
        updateState(state => ({...state, openPopUp: false, openMenu: true}))
    }, [])
    return (<>

            {!notFound && <div className={"no-border-map"}>
                {
                    openPopUp && <StationPopUp isMobile={isMobileDevice} closePopUp={onClosePopUp}
                                               onHistorySelected={onHistorySelected}
                                               station={selectedStation}
                                               units={data.units}
                                               zoom={lastLocation.zoom}
                    />
                }
                <div style={{
                    border: "none",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: "100vh",
                    width: "100vw"
                }} id="map"/>
                {data !== null && !openMenu && <MapLegend indexType={data.units.index} isMobileDevice={isMobileDevice}
                                             openDetail={openPopUp}/>}
                {loading && <Backdrop style={{
                    backdrop: {
                        zIndex: 9999,
                        color: '#fff',
                    }
                }} open={true}>
                    <CircularProgress/>
                </Backdrop>
                }
                <SwipeableDrawer
                    id={"drawer"}
                    disableDiscovery={isMobileDevice}
                    anchor={"bottom"}
                    open={openMenu}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <StationDetail indexType={data?.units.index} lastUpdate={data?.lastUpdate}
                                   station={selectedStation} isMobile={isMobileDevice}
                                   path={path} onClose={toggleDrawer(false)}
                                   organizationUnits={data?.units}
                    />
                </SwipeableDrawer>
                <MapSelector map={selectedMap} handleMapChange={setSelectedMapCallback}/>
                <div style={{
                    position: "absolute",
                    top: isMobileDevice ? "90px" : "30px",
                    left: "30px",
                    opacity: "0.5",
                    zIndex: "999"
                }}>
                    <img src={selectedMap === mapOptions.MAP ? "/bettair_color.png" : "/bettair_white.png"}
                         alt="watermark" style={{width: "110px", height: "auto"}}/>
                </div>

                <div style={{
                    position: "absolute",
                    bottom: "36px",
                    left: "20px",
                    zIndex: "999",
                }}><Typography
                    color={selectedMap === mapOptions.MAP ? "rgba(88,88,88,0.7)" : "rgba(209,209,209,0.9)"}
                    variant={"subtitle2"}>{`${t("version")} ${version}`}</Typography></div>
            </div>}
            {notFound && <NotFound/>}
        </>
    );
};

export default MapView;
