import React, {useCallback, useEffect, useState} from 'react';
import MobileDetail from "./mobile/MobileDetail";
import DesktopDetail from "./desktop/DesktopDetail";
import {roundAccurately} from "../../utils/roundNumbers";
import {getFilterSensors} from "../../utils/stationUtil";
import {getVariableName} from "../../utils/pollutantNames";
import {unitsMap} from "../../utils/unitsNames";
import {sensorDataRequest} from "../../../request/dataRequest";
import {useLocation} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {sensor} from "../../constants";


export const SHARE_URL_DOMAIN = "https://public.bettair.city";

const currentDate = new Date();

const StationDetail = ({isMobile, indexType, station, path, organizationUnits, onClose}) => {
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation()

    const [{selectedPollutant, units, data, threshold,loading, period}, updateState] = useState(
        {selectedPollutant: getFilterSensors(station)[0], data: [], loading: true, units: "", threshold:null,
            period: "data7day"})
    const [selectedAqiDate, setSelectedAqiDate] = useState(currentDate);

    useEffect(() => {
        if (selectedPollutant === sensor.aqi) {
            updateState(state => ({...state, loading: false}));
            return;
        }

        const organizationPath = location.pathname.split("/")[1];
        sensorDataRequest(organizationPath, station.id, selectedPollutant, period, (err, data) => {
            if (!err) {
                updateState(state => ({
                    ...state,
                    data: generatePlotData(data, selectedPollutant),
                    units: data[selectedPollutant]?.units,
                    loading: false,
                    threshold: data.threshold !== undefined ? data.threshold : null
                }));

            } else {
                updateState(state =>({
                    ...state,
                    data:null,
                    loading: false
                }))
                if(data.status === 404){
                    enqueueSnackbar(`${t("detail.error_500")}`, {variant: "error"})
                }else {
                    enqueueSnackbar(`${t("detail.error_404")}`, {variant: "info"})
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPollutant, period])

    const generatePlotData = (data, variable) => {
        const sensorData = data[variable];
        if (!sensorData) return null;
        const pollutantName = variable === sensor.noise ? t("ambient_noise") : getVariableName(variable, sensorData.units);
        const unit = variable === sensor.noise ? "dB" : variable === sensor.voc_iaq ? "IAQ" :
            (variable === sensor.pressure || variable === sensor.equivalent_pressure) ? "hPa": unitsMap.get(sensorData.units)
        return [{
            x: (sensorData.x),
            y: (sensorData.y).map(item => {
                return roundAccurately(item, 2);
            }),
            type: 'scatter',
            hovertemplate: `<b>${pollutantName}</b>: %{y} ${unit}`,
            hoverlabel: {namelength: 0},
            marker: {color: '#1A23D0'},
            unit:unit
        }]
    }

    const updatePeriodCallback = useCallback((event) => {
        updateState(state => ({...state, period: event.target.value, loading: true}))
    }, [])


    const pollutantCallback = useCallback((selectedPollutant) => {
        updateState(state => ({...state, selectedPollutant: selectedPollutant, loading: true}))
    }, [])

    return (
        <>
            {isMobile ? <MobileDetail indexType={indexType} station={station} pollutantCallback={pollutantCallback}
                                      data={data} units={units} loading={loading} lastUpdate={station.lastData}
                                      selectedPollutant={selectedPollutant} period={period}
                                      periodCallback={updatePeriodCallback} threshold={threshold} path={path}
                                      onClose={onClose} organizationUnits={organizationUnits}
                                      selectedAqiDate={selectedAqiDate} handleAqiDateChange={setSelectedAqiDate}
                /> :
                <DesktopDetail indexType={indexType} station={station} pollutantCallback={pollutantCallback}
                               data={data} units={units} lastUpdate={station.lastData} loading={loading}
                               selectedPollutant={selectedPollutant} period={period}
                               periodCallback={updatePeriodCallback} threshold={threshold} path={path}
                               organizationUnits={organizationUnits} selectedAqiDate={selectedAqiDate}
                               handleAqiDateChange={setSelectedAqiDate}
                />}
        </>
    );
};

export default StationDetail;
