import React, {useMemo} from 'react';
import { TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { getDateFnsLocale } from "../../../utils/dateUtil";

const currentDate = new Date();

const CalendarMonthSelector = ({className,date,handleDateChange,includeCurrentMonth = true,disable = false, limitMonths = null}) => {

    const {t} = useTranslation();

    const minDate = useMemo(() => {
        if (limitMonths) {
            return new Date(currentDate.getFullYear(), currentDate.getMonth() - limitMonths, 1);
        }
        return new Date("2019-12-01");
    }, [limitMonths]);

    const maxDate = useMemo(() => {
        return new Date(currentDate.getFullYear(),
            includeCurrentMonth ? currentDate.getMonth()+1:currentDate.getMonth(), 0);
    }, [includeCurrentMonth]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateFnsLocale()}>
        <DatePicker
            disabled={disable}
            className={className}
            views={['month','year']}
            label={t("calendar.select_date")}
            minDate={minDate}
            maxDate={maxDate}
            value={date}
            fullWidth
            onChange={handleDateChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    margin="normal"
                    helperText={null}
                    variant="standard"
                />
            )}
        />
        </LocalizationProvider>
    );
};

export default CalendarMonthSelector;
