import React from 'react';

import {useTranslation} from "react-i18next";
import {getVariableName} from "../../utils/pollutantNames";
import {getVariableUnit} from "../../utils/unitsNames";
import {roundAccurately} from "../../utils/roundNumbers";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    table: {
        borderRadius:6,
        width:250,
        background:"#efefef"
    },
});

const PollutantTable = ({station,units,maxHeight}) => {

    const { t } = useTranslation();

    const classes = useStyles();
    return (
        <TableContainer className={classes.table} style={{maxHeight:maxHeight}}>
            <Table  aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{t("popup.variable")}</TableCell>
                        <TableCell align="right">{t("popup.value")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(station.data).map((row) => (
                        <TableRow key={row[0]}>
                            <TableCell align="left">{row[0] === "noise" ? t("ambient_noise"):getVariableName(row[0], units)}</TableCell>
                            <TableCell align="right" style={{fontWeight:"bold"}}
                            >{`${roundAccurately(row[1].value,2)} ${getVariableUnit(row[0],units)}`}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PollutantTable;
