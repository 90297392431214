import {useTranslation} from "react-i18next";
import {useMessageDialog} from "../../../hooks/useMessageDialog";

const DisclaimerButton = ({style, ...props}) => {
    const {t} = useTranslation();
    const {showDialog} = useMessageDialog();
    const handleClick = async () => {
        await showDialog({
            title: t("disclaimer.title"),
            message: t("disclaimer.text")
        });
    };
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            ...style
        }} {...props}>
            <button style={{
                background: "none",
                border: "none",
                color: "#808080",
                cursor: "pointer",
                fontSize: 14,
                textDecoration: "underline",
                padding: 0
            }} onClick={handleClick}>
                {t("disclaimer.title")}
            </button>
        </div>
    );
};

export default DisclaimerButton;