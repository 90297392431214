import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {useCallback} from "react";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {handleSentryError} from "../handleSentryError";

const errorNamespace = "requestErrors";

const getErrorObjectFromResponse = (response) => {
    return response?.data || response?.error;
};


const useMessage = () => {
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const autoHideDuration = 3000;

    /** Check translation, and if not exists return undefined and register Sentry error
     *
     * @param {string} key - translation key
     * @returns {string | undefined} - translation value
     */
    const getTranslation = useCallback((key) => {
        if (typeof key !== "string") return undefined;
        // Check if translation exists in default language
        if (i18n.exists(key)) {
            // Check if translation exists in current language
            if (!i18n.exists(key, {lngs: [i18n.language]})) {
                handleSentryError(`Missing translation in language "${i18n.language}" for key "${key}"`);
            }
            return t(key);
        }
    }, [i18n, t]);

    const getMessageByErrorId = useCallback((error) => {
        if (error !== undefined) {
            const errorKey = `${errorNamespace}.${error}`;
            return getTranslation(errorKey);
        }
    }, [getTranslation]);

    const getMessageFromErrorObj = useCallback((errorObj) => {
        if (typeof errorObj === "string") {
            return getTranslation(errorObj);
        }
        return getMessageByErrorId(errorObj?.id) ??
            (typeof errorObj?.message === "string" ? errorObj.message : null);
    }, [getTranslation, getMessageByErrorId]);

    const setError = useCallback((object, unknownErrorMessage) => {
        const errorObj = getErrorObjectFromResponse(object) || object;
        const errorMessage = (
            getMessageFromErrorObj(errorObj) ||
                t(unknownErrorMessage) ||
                t(`${errorNamespace}.unknown`)
        );
        enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration });
    }, [getMessageFromErrorObj, t, enqueueSnackbar]);

    const setSuccess = useCallback((messageKey) => {
        const successMessage = getTranslation(messageKey);
        enqueueSnackbar(successMessage, { variant: "success", autoHideDuration });
    }, [getTranslation, enqueueSnackbar]);

    const setInfo = useCallback((messageKey) => {
        const message = getTranslation(messageKey);
        enqueueSnackbar(message, { variant: "info", autoHideDuration });
    }, [getTranslation, enqueueSnackbar]);

    const action = useCallback(snackbarId => (
        <IconButton aria-label="close" size="small" onClick={() => closeSnackbar(snackbarId)}>
            <CloseIcon fontSize="small" style={{color: '#fff'}} />
        </IconButton>
    ), [closeSnackbar]);

    const actionDark = useCallback(snackbarId => (
        <IconButton aria-label="close" size="small" onClick={() => closeSnackbar(snackbarId)}>
            <CloseIcon fontSize="small" style={{color: '#0a0a0a'}} />
        </IconButton>
    ), [closeSnackbar]);

    const setLargeInfo = useCallback((message, variant = "info", autoHide= true, dark = false, handleSnackbarClose = undefined) => {
        const style = dark ? {color: 'black', backgroundColor: "rgba(255,151,13,0.95)"} : {color: 'white'};
        const content = <>
            {getTranslation(message) || message}
        </>;
        enqueueSnackbar(content, {
            variant,
            autoHideDuration: autoHide ? 10000 : null,
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            persist: !autoHide,
            action: dark ? actionDark : action,
            onClose: handleSnackbarClose,
            style
        });
    }, [getTranslation, enqueueSnackbar, actionDark, action]);

    return { setError, setSuccess, setInfo, setLargeInfo };
};

export default useMessage;
