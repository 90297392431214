import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import CalendarComponent from "./CalendarComponent";
import CalendarMonthSelector from "./CalendarMonthSelector";
import CalendarDaysTittle from "./CalendarDaysTittle";
import CalendarLegend from "./CalendarLegend";
import useMessage from "../../../../hooks/useMessage";
import clsx from "clsx";
import {AQI_RANGE, sensor} from "../../../constants";
import CardLoading from "../../../common/CardLoading";
import {sensorDataRequest} from "../../../../request/dataRequest";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles({
    calendarTitle: {
        height: 30,
    },
    title:{
        display:"flex",
        alignItems:"center"
    },
    backButton: {
        marginLeft: 15
    },
    dateSelector: {
    },
    dateText: {
        marginLeft: 5,
        marginRight: 5
    },
    cardView: {
        minHeight: 400,
        justifyContent: "center",
    },
    legendContainer: {
        position: "absolute",
        bottom: 5,
        left: 0,
        right: 0,
        marginBottom: 5,
        paddingLeft: 10,
        paddingRight: 10
    },
    hidden: {
        display: "none"
    }
});

const currentDate = new Date();
const rangeDates = AQI_RANGE.map((value, index) => ({
    date: new Date(currentDate.getFullYear(), currentDate.getMonth() - index, 1),
    value
}));

const AQICalendarCardView = ({className, station, indexType, selectedDate, handleDateChange}) => {

    const {setError} = useMessage();
    const location = useLocation();
    const [{data, loading}, updateState] =
        useState({data:[], loading: false, error: false});

    const units = useMemo(() => ({index: indexType}), [indexType]);

    const fetchAQIData = useCallback(() =>{
        updateState(state =>{
            return {...state,data:[],loading: true,error:false};
        });

        const aqiRange = rangeDates.find(value => {
            const startOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
            return startOfMonth.getTime() === value.date.getTime();
        });

        const organizationPath = location.pathname.split("/")[1];
        sensorDataRequest(organizationPath, station.id, sensor.aqi, aqiRange.value, (err, data) => {
            if (!err) {
                updateState(state => {
                    return {...state, data, loading: false, error: false};
                });
            } else {
                updateState(state => {
                    return {...state, data: [], loading: false, error: true};
                });
                switch (err.response?.status) {
                    case 404:
                        break;
                    case 400:
                        setError("calendar.could_not_update_calendar_invalid_date");
                        break;
                    default:
                        setError("calendar.could_not_update");
                }
            }
        });
    },[setError, station.id, selectedDate, location.pathname]);

    useEffect(()=>{
        fetchAQIData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedDate,station.id]);

    const classes = useStyles();

    return (
        <Grid container item xs={12} className={clsx(classes.cardView, className)}>
            {loading ? <CardLoading/> : <>
                <Grid container item xs={12} columnSpacing={4} flexDirection="row-reverse" alignItems="flex-start">
                    <Grid item xs={12} md={2} container className={classes.dateSelector}>
                        <CalendarMonthSelector date={selectedDate} handleDateChange={handleDateChange}
                                               limitMonths={2} />
                    </Grid>
                    <Grid item xs={0} md={1} style={{height: 60}}/>
                    <Grid item xs={12} md={8} container>
                        <Grid item xs={12} className={classes.calendarTitle}>
                            <CalendarDaysTittle/>
                        </Grid>
                        <Grid item xs={12}>
                            <CalendarComponent data={data} date={selectedDate} indexType={units.index} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1} style={{height: 60}}/>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={12} md={10} style={{position: "relative"}}>
                        <div className={classes.legendContainer}>
                            <CalendarLegend indexType={units.index}/>
                        </div>
                    </Grid>
                </Grid>
            </>}
        </Grid>
    );
};

export default AQICalendarCardView;
