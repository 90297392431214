import {sensor} from "../constants";

export const unitsMap = new Map([
    ["ppb", "ppb"],
    ["ppm", "ppm"],
    ["g-m3", "g/m³"],
    ["ug-m3", "µg/m³"],
    ["mg-m3", "mg/m³"],
    ["celsius", "ºC"],
    ["percentage", "%"],
    ["fahrenheit", "ºF"],
    ["mps", "m/s"],
    ["mph", "mph"],
    ["knots", "kn"],
    ["degrees", "º"],
    ["hPa", "hPa"],
    ["dBA", "dB(A)"],
    ["IAQ", "VOC IAQ"],
    ["mm/h", "mm/h"],
    ["W/m²", "W/m²"],
    ["UV", "UV"]
]);


export const getVariableUnit = (variable, unit) => {

    const {pollutants, temperature, windSpeed, humidity} = unit;

    if (variable === sensor.pressure || variable === sensor.equivalent_pressure) {
        return unitsMap.get("hPa");
    }

    if ([sensor.pm1, sensor.pm2p5, sensor.pm4, sensor.pm10].includes(variable)) {
        return unitsMap.get("ug-m3");
    }

    if ([sensor.rh, sensor.ah, sensor.rh_meteo].includes(variable)) {
        if(humidity === "absolute")
            return unitsMap.get("g-m3");
        else
            return unitsMap.get("percentage");
    }

    if (variable === sensor.noise) {
        return unitsMap.get("dBA");
    }

    if (variable === sensor.temperature || variable === sensor.temperature_meteo) {
        return temperature === "celsius" ? unitsMap.get("celsius") : unitsMap.get("fahrenheit");
    }

    if ([sensor.no2, sensor.o3, sensor.no, sensor.h2s, sensor.nh3, sensor.so2].includes(variable)) {
        return pollutants === "usa" ? unitsMap.get("ppb") : unitsMap.get("ug-m3");
    }

    if (variable === sensor.co || variable === sensor.co2) {
        return pollutants === "usa" ? unitsMap.get("ppm") : unitsMap.get("mg-m3");
    }

    if (variable === sensor.wind_speed || variable === sensor.wind_gust) {
        return unitsMap.get(windSpeed);
    }

    if (variable === sensor.voc_iaq) {
        return unitsMap.get("IAQ");
    }

    if(variable === sensor.voc){
        return unitsMap.get("mg-m3");
    }

    if (variable === sensor.rain_rate) {
        return unitsMap.get("mm/h");
    }

    if (variable === sensor.uv_index) {
        return unitsMap.get("UV");
    }

    if (variable === sensor.solar_radiation) {
        return unitsMap.get("W/m²");
    }

};
