import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import caLocale from 'date-fns/locale/ca';
import {getI18n} from "react-i18next";

export const getLocale = () => {
    const i18n = getI18n();
    return i18n.language;
};

export const getDateFnsLocale = () => {
    const locale = getLocale();
    if (locale === "es") {
        return esLocale;
    } else if (locale === "ca") {
        return caLocale;
    }
    return enLocale;
};
