import React from 'react';
import {ImageList, ImageListItem, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    tittle:{
        marginBottom:8,
        marginTop:8
    },
    label:{
        fontWeight:"normal"
    }
});

const CalendarDaysTittle = () => {

    const {t} = useTranslation();
    let daysTittle = [t("calendar.monday"),t("calendar.tuesday"),
        t("calendar.wednesday"),t("calendar.thursday"),
        t("calendar.friday"),t("calendar.saturday"),
        t("calendar.sunday")];

    const classes = useStyles();

    return (
        <ImageList rowHeight={25} cols={7} gap={0} className={classes.tittle}>
            {daysTittle.map(value =>{
                return <ImageListItem key={value} >
                    <Typography className={classes.label} variant={"subtitle2"} align={"center"}>{value}</Typography>
                </ImageListItem>;
            })}
        </ImageList>
    );
};

export default CalendarDaysTittle;
