import {sensor} from "../constants";

export const getStationImage = (value,state,index)=>{

    if(state === "offline"){
        return "ic_warning.png"
    }

    if(value === undefined){
        return "ic_gray_sensor.png"
    }

    if(index === "aqi"){
        if(value <= 50){
            return "ic_green_sensor.png"
        }else if(value <= 100) {
            return "ic_yellow_sensor.png"
        }
        else if(value <= 150) {
            return "ic_orange_sensor.png"
        }
        else if(value <= 200) {
            return "ic_red_sensor.png"
        }
        else if(value <= 300) {
            return "ic_purple_sensor.png"
        }
        else {
            return "ic_dark_purple_sensor.png"
        }
    }else if(index === "caqi"){
        if(value <= 25){
            return "ic_caqi_green.png"
        }else if(value <= 50) {
            return "ic_caqi_yellow_green.png"
        }
        else if(value <= 75) {
            return "ic_caqi_yellow.png"
        }
        else if(value <= 100) {
            return "ic_caqi_orange.png"
        }
        else if(value > 100) {
            return "ic_caqi_red.png"
        }
    }
};

export const getFilterSensors = station => {
    return [...station?.sensors?.filter(item => {
        if (item === sensor.rh && station.sensors.includes(sensor.rh_meteo)) return false;
        if (item === sensor.temperature && station.sensors.includes(sensor.temperature_meteo)) {
            return false;
        }
        return item !== "wind";
    }), sensor.aqi];
}