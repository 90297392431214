import React, {useEffect, useState} from 'react';
import MobileLegend from "./MobileLegend";
import DesktopLegend from "./desktop/DesktopLegend";
import {useTranslation} from "react-i18next";
import {airQualityIndexColors} from "../../constants";
import {formatIndexRange, getIndexRange} from "../../utils/airQualityIndexUtil";
import {getAQILegend} from "bettairplaformutil/src/indexUtil";
import {NO_DATA_COLOR} from "./colors";

const MapLegend = ({indexType, isMobileDevice, openDetail}) => {

    const {i18n, t } = useTranslation();

    const [colors, setColors] = useState([]);
    const [indexInfo, setIndexInfo] = useState([]);
    const [rangeInfo, setRangeInfo] = useState([]);
    const [locale, setLocale] = useState(null);

    useEffect(() => {
        setLocale(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        if(indexType && locale !== null) {
            setColors([NO_DATA_COLOR,...airQualityIndexColors[indexType]]);
            let rangeInfo = formatIndexRange(getIndexRange(indexType))
            if(rangeInfo) rangeInfo = ["", ...rangeInfo]
            setRangeInfo(rangeInfo);
            setIndexInfo([t("noData"), ...getAQILegend(indexType, locale)]);
        }
    }, [indexType, locale, t]);


    return (isMobileDevice ? <MobileLegend openDetail={openDetail} data={{colors, indexInfo}}/> :
        <DesktopLegend openDetail={openDetail} data={{indexType, colors, indexInfo, rangeInfo}}/>);
};

export default MapLegend;
