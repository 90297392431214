import {indexTypes} from "../constants";

export const CATEGORICAL_INDEXES = [indexTypes.EAQI, indexTypes.ICQA, indexTypes.DIBA];

export const getIndexRange = (indexType) => {
    if (CATEGORICAL_INDEXES.includes(indexType)) return null;

    const range = {};
    range[indexTypes.AQI] = [50, 100, 150, 200, 300, 500];
    range[indexTypes.ISPU] = [50, 100, 200, 300, Infinity];
    range[indexTypes.AQHI] = [3, 6, 10, Infinity];
    range[indexTypes.APIMS] = [50, 100, 200, 300, Infinity];
    range[indexTypes.ICA] = [50, 100, 150, 200, 300, 500];
    range[indexTypes["AQI-QB"]] = [25, 50, Infinity];

    return range[indexType];
};

export const getValueColorPosition = (value, rangeValues) => {
    if (rangeValues === null) return value;
    return rangeValues.findIndex(item => value <= item);
};

export const formatIndexRange = (rangeValues) => {
    return rangeValues?.map((item, index) => {
        if (index === 0) return `<${item}`;
        if (item === Infinity) return `>${rangeValues[index-1]}`;
        return `${rangeValues[index-1]+1}-${item}`;
    }) ?? rangeValues;
};
