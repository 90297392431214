import React from 'react';
import {getVariableName} from "../../../utils/pollutantNames";
import {sensor} from "../../../constants";
import {useTranslation} from "react-i18next";
import {Box, Fade, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    toggleButtonContent: {
        maxWidth: 80,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
});

const PollutantToggleButtons = ({pollutantList, pollutantCallback, selectedPollutant, units}) => {
    const {t} = useTranslation()
    const classes = useStyles();

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            pollutantCallback(newAlignment)
        }
    };

    return (
        <Fade in={true} timeout={900}>
            <ToggleButtonGroup
                value={selectedPollutant}
                exclusive
                onChange={handleAlignment}
            >
                {pollutantList.map(pollutant => {
                    const label = pollutant === "noise" ? t("ambient_noise") : getVariableName(pollutant, units);
                    const pollutantTextKey = units.humidity === "absolute" && pollutant === sensor.rh
                        ? sensor.ah : pollutant;
                    const description = t(`pollutant_desc.${pollutantTextKey}`);
                    return (
                        <Tooltip key={pollutant} title={description} arrow>
                            <ToggleButton
                                data-testid={"toggle-" + pollutant}
                                value={pollutant}>
                                <Box className={classes.toggleButtonContent}>
                                    {label}
                                </Box>
                            </ToggleButton>
                        </Tooltip>
                    )
                })}
            </ToggleButtonGroup>
        </Fade>
    );
};

export default PollutantToggleButtons;
